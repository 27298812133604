<template>
  <div style="min-width: 1366px;">
    <AppBar />
    <Drawer />
    <v-main>
      <v-container fluid class="pc_container">
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import Drawer from "./Drawer.vue";
export default {
  name: "Home",
  components: { AppBar, Drawer },
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.pc_container {
  overflow: auto;
  max-height: calc(100vh - 64px);
}

.pc_container::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 16px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 16px;
}

.pc_container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 5px solid rgba(0, 0, 0, 0);
  box-shadow: 6px 0 0 #a5adb7 inset;
}

.pc_container::-webkit-scrollbar-thumb:hover {
  box-shadow: 6px 0 0 #4a4a4a inset;
}
</style>
