<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    dark
    :expand-on-hover="expandOnHover"
    :src="barImage"
    :mini-variant.sync="mini"
    mini-variant-width="72"
    permanent
    app
    width="260"
    v-bind="$attrs"
    class="scrollbar"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>
    <v-divider class="mb-1" />
    <v-list dense nav expand>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" contain>
          <v-img src="/favicon.svg" height="42" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h5">
            <strong class="mr-1 font-weight-black">我的</strong>
            <span class="primary--text font-weight-black">模板</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list dense expand nav>
      <!-- ENTIRE list is wrapped in a template -->
      <template v-for="(item, i) in menuItems">
        <!-- use v-if to determine if 2nd level nesting is needed -->
        <!-- if it's a menu item with no children -->
        <v-list-item
          v-if="!item.children"
          :key="`subheader-${i}`"
          :to="item.name"
          replace
          active-class="primary white--text"
        >
          <v-list-item-icon>
            <v-icon>{{ `${item.icon}` }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
        <!-- else if it has children -->
        <v-list-group
          v-else
          :key="`subgroup-${i}`"
          :prepend-icon="item.icon"
          active-class="white--text"
        >
          <!-- this template is for the title of top-level items with children -->
          <template #activator>
            <v-list-item-content>
              <v-list-item-title> {{ item.text }} </v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- this template is for the children/sub-items (2nd level) -->
          <template v-for="(subItem, j) in item.children">
            <!-- another v-if to determine if there's a 3rd level -->
            <!-- if there is NOT a 3rd level -->
            <v-list-item
              v-if="!subItem.children"
              :key="`subheader-${j}`"
              active-class="primary white--text"
              :to="subItem.name"
              replace
            >
              <v-list-item-icon>
                <v-icon v-text="`${subItem.icon}`" />
              </v-list-item-icon>
              <v-list-item-title>
                {{ subItem.text }}
              </v-list-item-title>
            </v-list-item>
            <!-- if there is a 3rd level -->
            <v-list-group
              v-else
              :key="`subgroup-${j}`"
              :prepend-icon="subItem.icon"
              active-class="white--text"
            >
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subItem.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(subSubItem, k) in subItem.children">
                <v-list-item
                  :key="`subheader-${k}`"
                  :value="true"
                  :to="subSubItem.name"
                  replace
                  active-class="primary white--text"
                >
                  <v-list-item-icon>
                    <v-icon>{{ `${subSubItem.icon}` }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ subSubItem.text }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";
export default {
  name: "CoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedItem: "",
    };
  },

  computed: {
    ...mapState(["barColor", "barImage", "menuItems"]),
    mini: {
      get() {
        return this.$store.state.mini;
      },
      set(val) {
        this.SET_MINI(val);
      },
    },
  },
  methods: {
    ...mapMutations({
      SET_MINI: "SET_MINI",
    }),
  },
};
</script>
<style>
/* .v-list-item--link:before {
  background-color: #ffffff33 !important;
} */
</style>
<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .8
  .v-list-item--link:before
    background-color: #ffffff33 !important
  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 0px

      +ltr()
        margin-right: 24px
        margin-left: 24px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important
  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px
      .v-list-item__title
        line-height: 42px
        font-weight: 400
        font-size: 16px
      .v-list-item__content
        padding-top: 0px
        padding-bottom: 0px
</style>
