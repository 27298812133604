<template>
  <v-app-bar app elevation="1" class="white">
    <v-app-bar-nav-icon @click="SET_MINIAUTO">
      <v-icon v-if="!mini">
        mdi-dots-vertical
      </v-icon>
    </v-app-bar-nav-icon>
    <v-app-bar-title> {{ title }}</v-app-bar-title>
    <v-spacer></v-spacer>
    <v-menu offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed v-bind="attrs" v-on="on" color="white">
          <v-icon>
            mdi-account
          </v-icon>
        </v-btn>
      </template>

      <v-card class="ma-0 pa-0">
        <v-list-item class="font-weight-black text-center">
          <v-list-item-title>{{ userName }}</v-list-item-title>
        </v-list-item>
        <v-divider class="mx-2" />
        <v-list-item @click="logout">
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>注销</v-list-item-title>
        </v-list-item>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { resetTokenAndClearUser } from "../utils";
import { mapState, mapMutations } from "vuex";
export default {
  name: "AppBar",
  data: () => ({
    userName: "管理员",
    title: "",
  }),
  computed: {
    ...mapState(["mini"]),
  },
  watch: {
    $route: {
      handler(route) {
        this.title = route.meta.text || "标题";
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({ SET_MINIAUTO: "SET_MINIAUTO" }),
    logout() {
      resetTokenAndClearUser();
      this.$router.replace("/Login");
    },
  },
};
</script>
